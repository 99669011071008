.contentl_Teamanagement {
  max-width: 100%;
  // min-width: 1448px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .contentlcontextheader {
    height: 10%;
    background: linear-gradient(180deg, #cbdbff 0%, #ffffff 100%);
    border-radius: 0px 0px 0px 0px;
    border: 1px solid #e2e2e2;
  }

  .contentllright {
    flex: 1;
    /* background: #F4F4F4; */
    display: flex;
    flex-direction: column;
  }

  .contentlHeader {
    height: 99px;
    // border-radius: 8px;
    // background: linear-gradient(135deg, #3482FF 0%, #CB98FA 100%);

    // .context {
    //     display: flex;
    //     flex-direction: column;
    //     padding: 23px 0 0 20px;
    // }

    // .contextspanul {
    //     font-weight: 500;
    //     font-size: 26px;
    //     color: #FFFFFF;
    //     line-height: 22px;
    //     text-align: left;
    //     font-style: normal;
    //     text-transform: none;
    // }

    // .contextspanli {
    //     margin-top: 15px;
    //     font-family: PingFang SC, PingFang SC;
    //     font-weight: 500;
    //     font-size: 16px;
    //     color: #E8F2FF;
    //     line-height: 22px;
    //     text-align: left;
    //     font-style: normal;
    //     text-transform: none;
    // }
  }

  //tabs
  .arco-tabs-header-ink {
    width: 0;
    height: 0;
  }

  .arco-tabs-header-nav-line {
    margin: 0 !important;
  }

  .arco-tabs-header-nav::before {
    background-color: none;
    width: 0;
    height: 0;
    border: none;
  }

  .arco-tabs-header {
    height: 38px;
    background: #f4f4f4;
    border-radius: 4px 4px 4px 4px;
  }

  .arco-tabs-header-title {
    width: 82px;
    height: 28px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
  }

  .arco-tabs-header-title-active {
    width: 118px;
    height: 38px;
    color: #417aff;
    border-radius: 4px 4px 4px 4px;
    background: #d1dfff;
  }

  .arco-tabs-header-title-text {
    width: 100%;
    display: inline-block;
  }

  //中部
  .Filtemember_contel_fap {
    width: 100%;
    background: #ffffff;
    border-radius: 12px 12px 0 0;
    margin-top: 20px;
  }
  .Filtememberscontext {
    width: calc(100% - 30px);
    height: 99px;
    display: flex;
    align-items: center;
    // border-bottom: 1px solid #eeeeee;
    margin: 0 15px;
    .Filtememberscontexttabs {
      width: 376px;
      .arco-tabs-header-nav-line .arco-tabs-header-title-text::before {
        left: 0 !important;
        right: 0 !important;
        // height: 38px !important;
      }
      .arco-tabs-header {
        height: 38px;
        background: #f4f4f4;
        border-radius: 4px 4px 4px 4px;
      }
      .arco-tabs-header-title {
        width: 82px;
        height: 28px;
        background: #f4f4f4;
        border-radius: 4px 4px 4px 4px;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        line-height: 22px;
        text-align: center;
        margin: 5px !important;
      }
    }

    .arco-tabs-header-title-active {
      width: 82px;
      height: 28px;
      background: #ffffff !important;
      border-radius: 4px 4px 4px 4px;
  
      font-size: 14px;
      color: #165dff !important;
      line-height: 22px;
      text-align: center;
      margin: 5px !important;
      font-weight: bold !important;
      .arco-tabs-header-title-text {
        width: 100%;
        display: inline-block;
      }
    }
  }

  .plearthPhone {
    width: 276px;
    height: 100%;
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
  }
  .optab_depename_select {
    width: 276px;
    height: 41px;
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    /* border: 1px solid #DDDDDD; */
    display: flex;
    align-items: center;
  }
  .dropdown-demo > .arco-btn {
    padding: 0 8px;
    font-weight: normal;
  }

  .dropdown-demo .arco-dropdown-popup-visible .arco-icon-down {
    transform: rotate(180deg);
  }
  .dropdown-demo {
    margin-left: 10px;
  }
  .Filtemembersbodyform {
    width: 100%;
    background-color: #ffffff;
    flex: 1;
    // border-top: 1px solid #EEEEEEEE;
  }

  .Filtememberscontextselectbody {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    .arco-input-group-wrapper .arco-input-inner-wrapper {
      background-color: #ffffff;
    }
  }

  // 下拉组件样式
  @setleonptioWidth: 200px;
  .setleonptio {
    width: @setleonptioWidth;
    height: 38px;
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    border: 1px solid #dddddd;
    display: flex;
    align-items: center;
    .setleonptiovalue {
      width: 153px;
      height: 22px;
      font-weight: 400;
      font-size: 14px;
      color: #86909c;
      line-height: 22px;
      text-align: left;
      font-style: normal;
      text-transform: none;
      margin: 8px;
    }
    .setleonptioicon {
      color: #86909c;
    }
  }
  .setleonptioitem {
    color: #86909c;
    width: @setleonptioWidth;
  }

  .Filtemembersbodyform {
    width: 100%;
    background-color: #ffffff;
    flex: 1;
    display: flex;
    .bvodyleft {
      width: 248px;
      border-right: 1px solid #eeeeee;
    }
    .bodyright {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-left: 30px;
      border-left: 1px solid #eeeeee;
      .tabletop {
        display: flex;
        height: 81px;
        // background-color: #417AFF;
        align-items: center;
        justify-content: right;
        padding-right: 15px;
        & > button {
          margin-left: 10px;
        }
      }
      // 覆盖
      .tablebody {
        flex: 1;
        padding: 0 10px;
        position: relative;
        display: flex;
        flex-direction: column;


        // .arco-table{
        //   flex: 1;
        //   display: flex;
        //   flex-direction: column;
        //   .arco-spin{
        //       display: flex;
        //       flex-direction: column;
        //       flex: 1;
        //       .arco-spin-children{
        //           display: flex;
        //           flex-direction: column;
        //           flex: 1;
        //           .arco-table-container{
        //               display: flex;
        //               flex-direction: column;
        //               flex: 1;
        //               .arco-table-content-scroll{
        //                   display: flex;
        //                   flex-direction: column;
        //                   flex: 1;
        //                   .arco-table-content-inner{
        //                       display: flex;
        //                       flex-direction: column;
        //                       flex: 1;
        //                   }
        //               }
        //           }
        //       }
        //   }
  
        // }
        .arco-checkbox-mask {
          // border: 1px solid !important;
        }
        .merber_pagination {
          position: absolute;
          bottom: 12px;
          right: 10px;
        }
        .arco-table-border .arco-table-container {
          border: none;
        }

        .arco-table-border .arco-table-th:first-child,
        .arco-table-border .arco-table-td:first-child {
          border-left: none;
        }
        thead > .arco-table-tr > .arco-table-th {
          border-bottom: none;
          font-weight: bold;
        }
        .arco-table-th {
          background-color: #f8fafd;
        }
        table{
          .arco-table-tr:nth-child(1){
            .arco-table-td {
              border-bottom: none;
              border-top: 1px solid #f8fafd;
            }
          }
          .arco-table-tr{
            .arco-table-th:nth-child(1){
              width: 5%;
            }
            .arco-table-th:nth-child(2){
                width: 23%;
            }
            .arco-table-th:nth-child(3){
              width: 13%;
            }
            .arco-table-th:nth-child(4){
              width: 22%;
            }
            .arco-table-th:nth-child(5){
                width: 12%;
            }
            .arco-table-th:nth-child(6){
              width: 13%;
            }
              .arco-table-th:nth-child(7){
                width: 12%;
            }
          }
        }   
        .arco-table-td {
          border-bottom: none;
          border-top: 1px solid rgb(229, 230, 235);
        }
        .arco-table-td:nth-child(1) {
          border-left: 1px solid #fff !important;
        }
        .arco-table-td:nth-child(7) {
          border-right: 1px solid #fff !important;
        }
        .arco-table-td {
          color: #333333;
          //   border-bottom: none;
        }
        .arco-checkbox-mask {
          // border: 2px solid #86909c;
          // border: 1px solid !important;
        }
        .arco-checkbox-checked .arco-checkbox-mask {
          border-color: transparent;
        }
        .arco-checkbox-indeterminate .arco-checkbox-mask {
          border-color: transparent;
        }
        .arco-pagination {
          width: 100%;
          justify-content: space-between;
        }
        .arco-pagination-list {
          margin-left: auto;
        }
        tr {
          border-bottom: none;
        }

        thead tr {
          border-left: none !important ;
          border-right: none !important ;
        }
        tr {
          // border: 1px solid transparent;
          //   border: 1px solid ;
        }
        .arco-table-hover
          .arco-table-tr:not(.arco-table-empty-row):hover
          .arco-table-td:not(.arco-table-col-fixed-left):not(
            .arco-table-col-fixed-right
          ) {
          background-color: #f8fafd;
        }
        .arco-pagination {
          .arco-pagination-option {
            .arco-select-view {
              background: #f8fafd;
            }
          }
          .arco-pagination-jumper {
            .arco-pagination-jumper-input {
              background: #f8fafd;
            }
          }
        }
        .selected-row {
          background-color: #165dff;
          border-bottom: none;
          td {
            border-bottom: 1px solid #165dff !important;
            border-top: 1px solid #165dff !important;
            background: #f8fafd;
          }
          td:nth-child(1) {
            border-left: 1px solid #165dff !important;
          }
          td:nth-child(7) {
            border-right: 1px solid #165dff !important;
          }
        }
        .arco-table-tr:nth-last-child(1) {
          .arco-table-td {
            border-bottom: 1px solid rgb(229, 230, 235);
          }
        }
      }
      .arco-table-border .arco-table-container::before {
        content: "";
        position: absolute;
        background-color: red;
        width: 100%;
        height: 0px;
        left: 0;
        bottom: 0;
        z-index: 2;
      }
    }
    .deeptab {
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      border-top: 1px solid #EEEEEE;
      .tabletop {
        display: flex;
        height: 81px;
        // background-color: #417AFF;
        align-items: center;
        justify-content: right;
        padding-right: 15px;
        & > button {
          margin-left: 10px;
        }
      }
      .tablebody {
        flex: 1;
        padding: 0 15px;
        position: relative;
        margin-bottom: 35px;
        .arco-table-border .arco-table-container {
          border: none;
        }

        .arco-table-border .arco-table-th:first-child,
        .arco-table-border .arco-table-td:first-child {
          border-left: none;
        }
        thead > .arco-table-tr > .arco-table-th {
          border-bottom: none;
          font-weight: bold;
        }
        .arco-table-th {
          background-color: #f8fafd;
        }
        .arco-table-td {
          border-bottom: none;
          border-top: 1px solid rgb(229, 230, 235);
        }
        .arco-table-td:nth-child(1) {
          border-left: 1px solid #fff !important;
        }
        .arco-table-td:nth-child(5) {
          border-right: 1px solid #fff !important;
        }
        .arco-table-td {
          color: #333333;
          //   border-bottom: none;
        }
        .arco-checkbox-mask {
          // border: 2px solid #86909c;
        }
        .arco-checkbox-checked .arco-checkbox-mask {
          border-color: transparent;
        }
        .arco-checkbox-indeterminate .arco-checkbox-mask {
          border-color: transparent;
        }
        .arco-pagination {
          width: 100%;
          justify-content: space-between;
        }
        .arco-pagination-list {
          margin-left: auto;
        }
        tr {
          border-bottom: none;
        }
        thead tr {
          border-left: none !important ;
          border-right: none !important ;
        }
        table{
          .arco-table-tr:nth-child(1){
            .arco-table-td {
              // border-bottom: none;
              border-top: 1px solid #f8fafd;
            }
          }
        }  
        tr {
          // border: 1px solid transparent;
          //   border: 1px solid ;
        }
        .arco-table-hover
          .arco-table-tr:not(.arco-table-empty-row):hover
          .arco-table-td:not(.arco-table-col-fixed-left):not(
            .arco-table-col-fixed-right
          ) {
          background-color: #f8fafd;
        }
        .arco-pagination {
          .arco-pagination-option {
            .arco-select-view {
              background: #f8fafd;
            }
          }
          .arco-pagination-jumper {
            .arco-pagination-jumper-input {
              background: #f8fafd;
            }
          }
        }
        .selected-row {
          background-color: #165dff;
          border-bottom: none;
          td {
            border-bottom: 1px solid #165dff !important;
            border-top: 1px solid #165dff !important;
            background: #f8fafd;
          }
          td:nth-child(1) {
            border-left: 1px solid #165dff !important;
          }
          td:nth-child(5) {
            border-right: 1px solid #165dff !important;
          }
        }
      }
    }

    .arco-table table {
      border-collapse: collapse !important;

      .selected-row {
        // .arco-table-operation{
        //   border-left: 2px solid #165dff !important;
        // }
        // border-top: 2px solid #165dff !important;
        // border-right: 2px solid #165dff !important;
        td {
          // border: 2px solid #165dff ;
          // border-left: none;
          // border-right: none;

          border-bottom-color: transparent !important;
          // border-top: 1px solid #165dff !important;
          // background-color: #f8fafd !important;
          color: #165dff !important;
        }
      }
    }
    .arco-table-tr:nth-last-child(1) {
      .arco-table-td {
        border-bottom: 1px solid rgb(229, 230, 235);
      }
    }
    .arco-table-border .arco-table-container::before {
      content: "";
      position: absolute;
      background-color: red;
      width: 100%;
      height: 0px;
      left: 0;
      bottom: 0;
      z-index: 2;
    }
  }

  .companydiv {
    display: flex;
    margin: 14px 0 11px 0;
  }
  .companytextp {
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin-left: 3px;
  }
  .tree {
    width: 190px;
    margin: 19px 0 0 33px;
    color: #417aff;
    .arco-tree {
      margin-left: 15px;
      font-weight: 400;
      font-size: 14px;
      color: #1d2129;
      line-height: 22px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }
  }
  .treeTopSearch {
    height: 36px;
    border-radius: 4px 4px 4px 4px;
    font-weight: 400;
    font-size: 12px;
    color: #86909c;
    line-height: 20px;
    text-align: left;
    .arco-input-inner-wrapper .arco-input {
      font-weight: 400;
      font-size: 12px;
      color: #86909c;
      line-height: 20px;
      text-align: left;
    }
    .arco-input-inner-wrapper {
      background-color: #ffffff !important;
    }
  }
  .arco-tree-node-switcher {
    width: 0;
    height: 0;
  }
  .arco-tree-node-is-leaf {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 170px;
    margin: 0;
    padding: 0 !important;
  }
  .arco-tree-node-selected {
    // margin: 0;
    // padding: 0 !important;
    //   width: 170px;
    //   height: 32px;
    //   background: #F1F3FD;
    //   border-radius: 4px 4px 4px 4px;
    //   font-weight: 400;
    //   font-size: 14px;
    //   color: #417AFF;
    //   line-height: 22px;
    //   text-align: left;
    //   font-style: normal;
    //   .arco-tree-node-switcher-icon{
    //       color: #417AFF;
    //   }
  }
  .opplist-lisotpost {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 170px;
    margin: 0;
    // padding: 0 !important;
    width: 170px;
    height: 32px;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    text-align: left;
    font-style: normal;
    padding: 0 0 0 15px !important;
    color: #333333;
  }
  .opdata {
    padding: 0 0 0 15px !important;
    margin: 0;
    // padding: 0 !important;
    width: 170px;
    height: 32px;
    background: #f1f3fd;
    border-radius: 4px 4px 4px 4px;
    font-weight: 400;
    font-size: 14px;
    color: #417aff;
    line-height: 32px;
    text-align: left;
    font-style: normal;
  }
  .arco-tree-node-title:hover {
    background: transparent;
    color: #417aff;
    // border-radius: 4px 4px 4px 4px;
  }

  .imgbutton {
    height: 38px;
    // background: #417AFF;
    border-radius: 2px 2px 2px 2px;

    display: flex;
    align-items: center;
    img {
      margin: 10px 8px 10px 6px;
    }
  }
  .header-container {
    .imgbuttoninvet {
      height: 38px;
      // background: #417AFF;
      border-radius: 6px 6px 6px 6px;
      background-color: #ffffff !important;
      display: flex;
      align-items: center;
      color: #8a38f5 !important;
      img {
        width: 18px;
        height: 18px;
        margin: 10px 8px 10px 6px;
      }
    }
    .imgbuttoninvetv {
      height: 38px;
      // background: #417AFF;
      border-radius: 6px 6px 6px 6px;
    background: #F1F1F1 !important;
      display: flex;
      align-items: center;
      color: #666666 !important;
      img {
        width: 18px;
        height: 18px;
        margin: 10px 8px 10px 6px;
      }
    }
    .arco-btn-primary:not(.arco-btn-disabled):not(.arco-btn-loading):hover {
      border-color: transparent;
      background-color: #ffffff;
      color: #8a38f5;
    }
  }

  .role_contenl {
    width: 100%;
    margin-left: 33px;
    .role_header {
      margin: 20px 0;
      font-weight: 500;
      font-size: 14px;
      color: #333333;
      line-height: 22px;
      text-align: left;
      font-style: normal;
      text-transform: none;
      display: flex;
      .role_hedaerpimg {
        display: flex;
        align-items: center;
        img {
          margin-left: 15px;
        }
        span {
          font-weight: 600;
          font-size: 16px;
          color: #6187ff;
          line-height: 22px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          margin-left: 3px;
        }
      }
    }
    .role_body {
      width: 100%;
      display: flex;
      .role_bodyto {
        display: flex;
        flex-direction: column;
        position: relative;
        max-width: 360px;
        min-width: 320px;
        height: 378px;
        margin-right: 42px;
        // border: 1px solid #DDDDDD;
        // border-top: none;
        // box-sizing: border-box;
        // border-radius: 8px;
      }
      .role_poab_img {
        position: absolute;
        top: 15%;
        left: 10%;
      }
      .role_bodyback {
        width: 100%;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        border: 1px solid #dddddd;
        border-top: none;
        box-sizing: border-box;
        border-radius: 0 0 8px 8px;
        /* padding: 20px 0 0 20px; */
      }
      .role_bodyback_header {
        width: 100%;
        display: flex;
        align-items: center;
        margin-left: 20px;
        margin-top: 15px;
        span {
          font-weight: 400;
          font-size: 14px;
          color: #333333;
          line-height: 20px;
          text-align: left;
          font-style: normal;
          text-transform: none;
        }
        div {
          width: 238px;
          height: 0px;
          border: 1px solid;
          margin-left: 20px;
        }
      }
    }
  }
  .role_bodyback_body {
    margin-top: 10px;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    margin-left: 20px;
    .role_bodyback_bopp {
      display: flex;
      flex-direction: column;
      width: 183px;
      margin: 0 30px 0 0;
      span {
        height: 22px;
        display: inline-block;
        width: 100%;
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-bottom: 16px;
      }
      span:nth-child(1) {
        font-weight: 500;
        font-size: 14px;
        color: #1d2129;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }
  }
}
.arco-dropdown-menu{
  padding: 0;
}
.popinformation {
  position: absolute;
  width: 136px;
  // height: 110px;
  // background:transparent;
  box-shadow: 0px 1px 1px 0px rgba(167, 176, 188, 0.2);
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #eeeeee;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9;
  .arco-tooltip-content {
    background: #ffffff !important;
  }
  .arco-tooltip-arrow {
    width: 0 !important;
    height: 0 !important;
  }
  .popin_img {
    width: 112px !important;
    height: 28px;
    border-radius: 4px 4px 4px 4px;
    background: transparent;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    display: flex;
    align-items: center;
    margin-top: 8px;

    img {
      margin-left: 7px;
    }
    span {
      margin-left: 7px;
    }
  }
  .popin_img:nth-child(1) {
    margin-top: 2px;
  }
  .popin_img:nth-child(2-3) {
    margin-top: 8px;
  }

  .popin_img:hover {
    background: linear-gradient(
      135deg,
      rgba(22, 93, 255, 0.15) 0%,
      rgba(203, 152, 250, 0.15) 100%
    ) !important;
    border-radius: 4px 4px 4px 4px !important;
    color: #333333 !important;
  }
}
// .arco-trigger{
//   width: 200px;
//   height: 200px;
// }

