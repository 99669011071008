.modal_add{
    .arco-modal-footer {
        border: none;
    }
    .arco-modal-header {
        background: linear-gradient( 135deg, rgba(65,122,255,0.3) 0%, rgba(171,80,255,0.084) 100%);
        height: 57px;
    }
}

.addmobal_inputbox{
        width: 100%;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        padding: 12px 0 0 30px;
        .add_teme_name{
            margin-bottom: 6px;
        }
        .add_depe_name{
            margin-bottom: 6px;
        }
        .moble_dep{
            width: 438px;
            height: 36px;
            // border: 1px solid #F2F3F5;
            border-radius: 2px;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
        } 
        .modeble_temename{
            // background-color: #F7F7F7;
            margin-bottom: 14px;
            color: #333333 !important;
        }
        .modeble_temename[disabled] {
            color: #000 !important;
        }
    
}
.delemodal_pbox{
    display: flex;
    flex-direction: column;
    margin-left: 63px;
    margin-top: 25px;
    p:nth-child(1){
        font-weight: 500;
        font-size: 16px;
        color: #1D2129;
        line-height: 24px;
        text-align: left;
    }
    p:nth-child(2){
      font-weight: 400;
    font-size: 14px;
    color: #1D2129;
    margin-top: 11px;
    }
}
.depo_modaldeil{
    display: flex;
    flex-direction: column;
    margin-left: 29px;
    margin-right: 30px;
    .depo_modaldeil_divin {
        margin-top: 16px;
        p{
            margin: 0 0 6px 3px;
        }
        .depo_modaldeil_input{
            width: 438px;
            height: 36px;
            background: #FAFAFA;
            border-radius: 2px 2px 2px 2px;
            border: 1px solid #DDDDDD;
        }
    }
    .arco-collapse-item-content-box{
        display: flex;
        flex-wrap: wrap;
        padding: 0 10px;
        .list-item{
            width: 50%;
            height: 36px;
            font-weight: 400;
            font-size: 14px;
            color: #1D2129;
            line-height: 36px;
            text-align: left;
            font-style: normal;
            margin-left: 0px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
   
    
    
}
.depe_detail{
    
    .arco-collapse-item-header{
        background: linear-gradient(135deg, rgba(211, 224, 255, 0.3) 0%, rgba(234, 212, 255, 0.3) 100%);
        border-radius: 2px 2px 0px 0px;
    }
}

