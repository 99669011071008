.meber_modal_memuyq {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    margin-top: 24px;

    // margin-bottom: 19px;
    .meber_mobal_selname {
        margin-bottom: 6px;
        font-weight: 400;
        font-size: 14px;
        color: #1D2129;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .meber_mobal_selelon_name {
        width: 438px;
        height: 36px;
    }

    .meber_mobal_seldepe {
        margin: 0px 0 6px 0;
        font-weight: 400;
        font-size: 14px;
        color: #1D2129;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .meber_mobal_selelon_depe {
        width: 438px;
        height: 36px;
    }

    .arco-select-view {
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #F2F3F5;
        background: #FFFFFF;
    }

}

.merber_body_contel {
    width: 100%;
    height: calc(100% - 1px);
    display: flex;
    border-top: 1px solid #EEEEEE;
}

// .bodyleft{
//     border-top: 1px solid #EEEEEE;
// }
// .bodyright{
//     border-top: 1px solid #EEEEEE;
// }
.depe_yqmobal_null {
    height: 178px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.depe_yqmobal_null_title {
    font-weight: 400;
    font-size: 14px;
    color: #979797;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    text-transform: none;
}

#depe_yqmobal_null_button {
    width: 80px;
    height: 28px;
    border-radius: 2px 2px 2px 2px;
    border: 1px solid #165DFF;
    font-weight: 400;
    font-size: 14px;
    color: #165DFF !important;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    padding: 0 0 0 4px;
    background: #FFFFFF;
    margin-top: 14px;
}

.arco-select-option-wrapper {
    padding: 0;

    .arco-select-option {
        margin-left: 0;
        padding: 0 12px;

    }
}

.select-demo-hide-option-checkbox {
    label {
        display: none;
    }
}

.depe_select_modal_iconcheck {
    display: flex;
    justify-content: space-between;
    padding: 0;
}

.arco-tag-content {
    .icon_wrapper {
        display: none;
    }
}

.movemerber_modal_wearpper {
    width: 464px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
}

.movemerber_modal_bodydiv {
    display: flex;
    align-items: center;
    margin: 28px 0 0 89px;

    .movemerber_modal_bodyp {
        margin-right: 25px;
    }

    .arco-select-view {
        width: 220px;
        height: 36px;
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #DDDDDD;
        background: #FFFFFF;
    }
}

.disblae_modals {
    margin: 26px 0 0 61px;

    .disblae_modals_titeldis {
        font-weight: 500;
        font-size: 16px;
        color: #1D2129;
        line-height: 24px;
        text-align: left;
        font-style: normal;
        text-transform: none;
    }

    .disblae_modals_tit_wrapp {
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-top: 6px;
    }
}
