.moadal-merber-contenl {
    margin: 24px;

    .moadal-merber-header-plehone {
        margin-bottom: 16px;

        span:nth-child(1) {
            font-weight: 400;
            font-size: 14px;
            color: #1D2129;
            line-height: 22px;
            text-align: left;
            font-style: normal;
            text-transform: none;
            display: inline-block;
            margin-right: 20px;
        }

        span:nth-child(2) {
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            line-height: 22px;
            text-align: left;
            font-style: normal;
            text-transform: none;
            display: inline-block;
            margin-right: 16px;
        }

        span:nth-child(3) {}

    }

    .merbrr-header-nametitle {
        font-weight: 400;
        font-size: 14px;
        color: #1D2129;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-bottom: 8px;
    }

    #Merbername_input {
        width: 438px;
        height: 32px;
        border-radius: 2px 2px 2px 2px;
        background: #FFFFFF;
        border: 1px solid #F2F3F5;
    }

    .merbrr-header-depeseleect {
        margin-bottom: 6px;
        font-weight: 400;
        font-size: 14px;
        color: #1D2129;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-top: 16px;
    }

    .arco-select-view {
        width: 438px;
        height: 36px;
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #F2F3F5 !important;
        background: #FFFFFF;
    }

    .arco-select-focused {}

    .arco-select-open {
        border: none;
    }

    .merbrr-header-roleselect {

        display: inline-block;
        margin-top: 16px;
        margin-bottom: 7px;
    }

    .merbrr-header-status {
        margin-top: 16px;
        margin-bottom: 7px;
    }

    .merbrr-header-infoswitch {
        display: flex;
        align-items: center;

        img {
            width: 17px;
            height: 17px;
            margin-right: 5px;
        }

        span {
            font-weight: 400;
            font-size: 12px;
            color: #333333;
            line-height: 22px;
            text-align: left;
            font-style: normal;
            text-transform: none;
        }
    }


}

.disblae_modals_body {
    .disblae_modals_headerdepe {
        font-weight: 500;
        font-size: 16px;
        color: #1D2129;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        display: flex;
        justify-content: left;
    }

    .disblae_modals_headername {
        font-weight: 400;
        font-size: 14px;
        color: #666666;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-top: 6px;
    }
}
