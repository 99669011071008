.team_role_wrapple{
    padding: 0 20px 20px 20px;
    width: 100%;
    display: flex;
    height:  calc( 100% - 20px );
    .role_table{
        width: 304px;
        margin-right: 20px;
        height:calc( 100% - 28px - 16px - 2px);
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #EEEEEE;
        // border-top: none;
        padding: 28px 16px 16px 16px;
        .role_table_contel{
            border-top: 1px solid #EEEEEE;
            height: 100%;
            display: flex;
            flex-direction: column;
            position: relative;
            .role_table_title{
                width: 120px;
                position: absolute;
                top: -13px;
                font-weight: 500;
                font-size: 14px;
                color: #333333;
                line-height: 22px;
                text-align: left;
                font-style: normal;
                background-color: #FFFFFF;
            }
            .table_contel{
                margin-top: 27px;
                flex: 1;
                background: rgba(249,249,249,0.6);
                .role_table_item_text{
                    padding: 9px 0 9px 18px;
                    font-weight: 500;
                    font-size: 14px;
                    color: #666666;
                    line-height: 22px;
                    text-align: left;
                    font-style: normal;
                }
                .item_active{
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 22px;
                    text-align: left;
                    font-style: normal;
                    background: #F1F6FF;
                    border-radius: 0px 0px 0px 0px;
                    border-right: 3px solid #165DFF;
                    .role_table_item_text{
                        color: #165DFF;
                        font-weight: bold;
                     
                    }
                }
            }
        }
    }
    .role_contel{
        
        flex: 1;
        .table_R_contel{
            width: 100%;
            height: 100%;
            display: flex;
         
            flex: 1;
            .arco-spin{
                display: flex;
                flex: 1;
                .arco-spin-children{
                    display: flex;
                    flex: 1;
                    .arco-table-container{
                        display: flex;
                        flex: 1;
                        .arco-table-content-scroll{
                            display: flex;
                            flex: 1;
                            .arco-table-content-inner{
                                display: flex;
                                flex: 1;
                                table{
                                    flex: 1;
                                    border-radius: 4px 4px 4px 4px;
                                }
                            }
                          
                        }
                    }
                }
            }
        }
        .arco-table-tr{
            .arco-table-td:nth-child(1){
                border-right: 1px solid #EEEEEE;
            }
            .arco-table-th:nth-child(1){
                border-right: 1px solid #EEEEEE;
            }
       
            .arco-table-th{
                background: #F8F9FA;
            }
        }
        .tab_check_contel{
            display: flex;
            width: 670px;
            flex-wrap: wrap;
            padding: 0 0 0  20px;
            .tab_check_item{
                width: 97px;
                margin-right: 70px;
                padding: 10px 0 10px 0 ;
                font-weight: 400;
                font-size: 14px;
                color: #333333 !important;
                line-height: 22px;
                text-align: left;
                font-style: normal;
                .arco-checkbox-text{
                    color: #333333 !important;
                }
            }
        }
    }

}
